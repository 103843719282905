import styled from "styled-components"

export const Highlight = styled.em`
  font-style: normal;
  position: relative;
  color: ${({ theme }) => theme.colors.dark[100]};

  ::selection {
    background: ${({ theme }) => theme.colors.blue[5]};
  }

  &:after {
    content: "";
    background: ${({ theme: { colors } }) => colors.blue[25]};
    width: 100%;
    height: 1rem;
    display: inline;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
  }
`
