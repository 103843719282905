import { DefaultTheme } from "styled-components"

export const theme: DefaultTheme = {
  colors: {
    dark: {
      125: "#000000",
      100: "#002b47",
      75: "#3f6175",
      50: "#7f95a3",
      25: "#abc8d7",
      10: "#dce9f1",
      0: "#ffffff",
    },
    blue: {
      150: "#0079b0",
      125: "#0088c6",
      100: "#009ee6",
      75: "#38b6ec",
      50: "#7dcff3",
      25: "#bee7f9",
      5: "#f2fafe",
    },
  },
  spacing: {
    0: "0",
    1: "0.25rem",
    2: "0.5rem",
    3: "0.75rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    8: "2rem",
    10: "2.5rem",
    12: "3rem",
    16: "4rem",
    20: "5rem",
    24: "6rem",
    32: "8rem",
    40: "10rem",
    48: "12rem",
    56: "14rem",
    64: "16rem",
  },
}
