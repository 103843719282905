import React, { FC, ReactNode } from "react"
import styled from "styled-components"

type Props = {
  children: ReactNode
}

const Typo = styled.p`
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.dark[100]};
  text-decoration: none;
  font-size: 1.2rem;
  margin: 0;
  line-height: 1.5rem;
  display: inline-block;

  ::selection {
    background: ${({ theme }) => theme.colors.blue[5]};
  }
`

export const Label: FC<Props> = ({ children }) => <Typo>{children}</Typo>
