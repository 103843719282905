import React, { FC, ReactNode } from "react"
import styled from "styled-components"

type Props = {
  children: ReactNode
}

const Typo = styled.p`
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.dark[100]};
  text-decoration: none;
  font-size: 1.25rem;
  margin: 0 0 1.5rem;
  line-height: 2rem;

  ::selection {
    background: ${({ theme }) => theme.colors.blue[5]};
  }
`

export const Lead: FC<Props> = ({ children }) => <Typo>{children}</Typo>
