import React, { FC } from "react"
import styled from "styled-components"

const Svg = styled.svg`
  margin: ${({ theme }) => theme.spacing[16]} auto 0;
  display: block;
`

const Rect = styled.rect`
  fill: ${({ theme }) => theme.colors.blue[100]};
`
const Polygon = styled.polygon`
  fill: #fff;
`

type Props = { size?: number }

export const Logo: FC<Props> = ({ size = 80 }) => (
  <Svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 425.2 424.8"
  >
    <Rect width="425.2" height="424.8" />
    <Polygon points="70.87 179.67 240.94 81.48 297.64 114.21 127.56 212.4 70.87 179.67" />
    <Polygon points="297.64 212.4 354.33 245.13 184.25 343.33 127.56 310.6 297.64 212.4" />
    <Polygon points="141.73 220.59 198.43 253.32 283.46 204.22 226.77 171.49 141.73 220.59" />
  </Svg>
)
