import { Link as GatsbyLink } from "gatsby"
import React, { FC } from "react"
import styled from "styled-components"

const Container = styled.header<{ hasNavigation: boolean }>`
  display: block;
  width: 100%;
  background: ${({ theme }) => theme.colors.blue[5]};
  text-align: center;
  padding: ${({ theme }) => theme.spacing[8]} 0;
  margin: 0 0
    ${({ theme, hasNavigation }) => (hasNavigation ? "0" : theme.spacing[16])};

  @media only screen and (min-width: 768px) {
    padding: ${({ theme }) => theme.spacing[16]} 0;
    margin: 0 0
      ${({ theme, hasNavigation }) => (hasNavigation ? "0" : theme.spacing[32])};
  }
`

const Navigation = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: ${({ theme }) => theme.colors.blue[5]};
  margin-bottom: 4rem;
`

const NavigationLink = styled.div<{ active: boolean }>`
  display: inline-flex;
  align-items: flex-end;
  background: ${({ active }) => (active ? "white" : "transparent")};
  padding: ${({ theme }) => `${theme.spacing[1]} ${theme.spacing[2]}`};
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  text-decoration: none;
  font-size: 0.8rem;
  margin: 0;
  line-height: 1.4rem;

  max-width: 110px;

  a {
    color: ${({ theme }) => theme.colors.dark[100]};
  }

  &::selection {
    background: ${({ theme }) => theme.colors.blue[5]};
  }

  @media only screen and (min-width: 768px) {
    padding: ${({ theme }) => `${theme.spacing[4]} ${theme.spacing[8]}`};
    font-size: 1.2rem;
    max-width: none;
  }
`

const Link = styled(GatsbyLink)<{ active: boolean }>`
  text-decoration: none;
  padding-bottom: ${({ theme }) => theme.spacing[1]};
  ${({ theme, active }) => (active ? `border-bottom: 2px solid #009fe3;` : "")};
`

const Heading = styled.h1`
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.dark[100]};
  font-size: 1rem;
  margin: 0 0 0.5rem;
`

const Subheading = styled.h2`
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.dark[100]};
  font-size: 1rem;
  margin: 0 0 0.5rem;
`

const Logo = styled.svg`
  padding: 0 2rem;
  max-width: 200px;
  display: inline-flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 0 0 ${({ theme }) => theme.spacing[6]};
`

const Divider = styled.div`
  margin-right: 2rem;
  width: 1px;
  margin: 0 2rem;
  background: gray;
  height: 28px;
  margin: 0 0 ${({ theme }) => theme.spacing[6]};
`

const Logos = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
`

type Props = {
  heading?: string
  subheading?: string
  navigation?: { label: string; link: string; active?: boolean }[]
  withMutaborAsPartner?: boolean
}

export const Header: FC<Props> = ({
  heading = "Eingabe Best of Swiss Web 2020",
  withMutaborAsPartner = false,
  subheading,
  navigation,
}) => (
  <>
    <Container hasNavigation={!!navigation}>
      {withMutaborAsPartner ? (
        <Logos>
          <Logo
            width="211"
            height="28"
            viewBox="0 0 181 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M49.7291 15.2758C49.7291 21.3785 45.9212 24.5963 40.0916 24.5963C34.3086 24.5963 30.5007 21.3755 30.5007 15.2758V1.31424H36.0918V15.9094C36.0918 18.1577 37.971 19.5692 40.1411 19.5692C42.2094 19.5692 44.1409 18.1577 44.1409 15.9094V1.31424H49.7291V15.2758ZM89.1371 10.4019L86.4172 16.4928L91.8192 16.4899L89.1371 10.4019ZM95.2693 24.2339L93.6373 20.798H84.6428L82.9963 24.2339H76.739L89.111 0L101.477 24.2339H95.2693ZM119.606 17.1735C119.606 15.6029 118.687 14.7159 117.04 14.7159L112.488 14.7189V19.4837L117.183 19.522C118.687 19.522 119.606 18.6999 119.606 17.2265V17.1735ZM118.637 8.07697C118.637 6.80104 117.817 5.95827 116.415 5.95827L112.49 5.96122V10.2104L116.415 10.2133C117.849 10.2163 118.64 9.37647 118.637 8.02098V8.07697ZM125.665 18.0575C125.665 21.4905 123.405 24.4077 118.314 24.2456L107.065 24.2339V1.32897L117.587 1.33192C121.791 1.33192 124.682 3.83074 124.682 7.21653C124.682 9.66525 123.777 11.5364 120.967 12.223C123.87 12.6149 125.665 15.4084 125.665 18.0575ZM149.606 12.6031C149.557 9.03171 146.668 6.15865 143.198 6.20875C139.675 6.25884 136.932 9.23503 136.981 12.8536C137.031 16.4221 139.919 19.2981 143.396 19.248C146.915 19.1979 149.656 16.2217 149.606 12.6031ZM155.436 12.7534C155.436 19.5338 149.984 25 143.294 25C136.606 25 131.152 19.5309 131.152 12.7534C131.152 5.92586 136.606 0.450849 143.294 0.450849C149.984 0.450849 155.436 5.92586 155.436 12.7534ZM68.7684 6.71853V24.2397H63.0174V6.71853H56.126V1.28182H75.6918V6.71853H68.7684ZM174.856 24.2397L170.862 17.2413L167.325 17.2354V24.2397H161.594V1.28182H171.971C176.669 1.28182 179.185 5.53984 179.185 9.0818C179.185 12.5648 177.212 14.3505 175.956 15.3819L180.997 24.2427H174.856V24.2397ZM173.518 9.09654C173.518 7.28135 172.357 6.12329 170.469 6.12329L167.325 6.15865V12.3291H170.516C171.874 12.3291 173.518 11.3655 173.518 9.01108V9.09654ZM11.2578 7.38154L0 0.580505V24.2368H5.45144V10.5404L11.2578 13.9527L17.0641 10.5404V24.2368H22.5155V0.580505L11.2578 7.38154Z"
              fill="black"
            />
          </Logo>
          <Divider />
          <Logo
            viewBox="0 0 236.54754 45.69664"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="#002c46">
              <path d="m20.61626 23.69987a12.49114 12.49114 0 0 0 -5.41733-1.38217c-1.16026 0-3.15.49785-3.15 1.934 0 1.93488 3.15 2.21081 4.53217 2.48758 4.6984.94 8.2357 3.15083 8.2357 8.40109 0 7.46108-6.79867 10.55622-13.37626 10.55622a21.99256 21.99256 0 0 1 -11.44054-3.25965l3.64784-7.01975a16.18239 16.18239 0 0 0 7.95893 2.87406c1.27163 0 3.15-.60839 3.15-2.156 0-2.21081-3.15-2.32136-6.3-3.15-3.15083-.82947-6.30165-2.377-6.30165-7.46191 0-6.96407 6.30165-10.05921 12.49193-10.05921a22.53772 22.53772 0 0 1 9.34028 1.82434z" />
              <path d="m36.889 19.94148h.11054a10.49572 10.49572 0 0 1 8.9538-4.47731c4.09 0 7.62731 1.65811 9.6719 5.4165a10.82974 10.82974 0 0 1 9.61787-5.4165c7.18432 0 9.6719 4.6984 9.6719 11.21947v18.07382h-10.05919v-13.54082c0-2.92974.11054-7.73785-4.14569-7.73785-4.64271 0-4.80811 4.36594-4.80811 7.73785v13.54082h-10.0592v-13.54082c0-3.15083 0-7.95893-4.47648-7.95893-4.47731 0-4.47731 4.80811-4.47731 7.95893v13.54082h-10.05922v-28.35328h10.05919z" />
              <path d="m110.2383 44.75746h-10.05838v-3.15h-.11137c-1.76865 2.81754-5.25027 4.08918-8.56649 4.08918-8.40109 0-14.31461-7.01975-14.31461-15.14325 0-8.12516 5.803-15.08922 14.20407-15.08922a11.5688 11.5688 0 0 1 8.7884 3.7027v-2.76269h10.05838zm-22.66-14.14921a6.46716 6.46716 0 0 0 12.93243 0 6.46645 6.46645 0 0 0 -12.93243 0z" />
              <path d="m123.583 21.04689h.11137c1.93488-3.48245 5.2511-5.03 9.174-5.03h1.93488v9.67271a8.13945 8.13945 0 0 0 -4.2554-.94c-5.58189 0-6.9649 3.7027-6.9649 8.56649v11.44137h-10.05835v-28.35328h10.0584z" />
              <path d="m150.31326 44.75746h-10.06v-20.06356h-3.2597v-8.28972h3.2597v-8.51247h10.06v8.51247h5.69326v8.28972h-5.69326z" />
              <path d="m158.33515 16.40886v28.34091h10.05972v-28.34091z" />
              <path d="m188.959 30.93987 6.74382-14.53569h11.27349l-14.81082 28.35328h-6.413l-14.86734-28.35328h11.27515z" />
              <path d="m236.54754 32.26636h-21.169c0 4.09 2.156 6.30082 6.3 6.30082a5.0283 5.0283 0 0 0 4.80894-2.59812h9.67273c-1.60242 6.6873-7.95893 9.72758-14.42515 9.72758-9.39679 0-16.41654-5.306-16.41654-15.08839 0-9.45165 6.46788-15.14408 15.69844-15.14408 9.83729 0 15.53056 6.07974 15.53056 15.75247zm-9.34028-5.8038a5.65886 5.65886 0 0 0 -5.69326-4.47648c-2.93058 0-5.36081 1.54757-5.9692 4.47648z" />
            </g>
            <path
              d="m162.28417 0a5.6271 5.6271 0 0 0 1.0893 11.14913 5.519 5.519 0 0 0 3.91495-1.6z"
              fill="#009fe3"
            />
          </Logo>
        </Logos>
      ) : (
        <Logo
          viewBox="0 0 236.54754 45.69664"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="#002c46">
            <path d="m20.61626 23.69987a12.49114 12.49114 0 0 0 -5.41733-1.38217c-1.16026 0-3.15.49785-3.15 1.934 0 1.93488 3.15 2.21081 4.53217 2.48758 4.6984.94 8.2357 3.15083 8.2357 8.40109 0 7.46108-6.79867 10.55622-13.37626 10.55622a21.99256 21.99256 0 0 1 -11.44054-3.25965l3.64784-7.01975a16.18239 16.18239 0 0 0 7.95893 2.87406c1.27163 0 3.15-.60839 3.15-2.156 0-2.21081-3.15-2.32136-6.3-3.15-3.15083-.82947-6.30165-2.377-6.30165-7.46191 0-6.96407 6.30165-10.05921 12.49193-10.05921a22.53772 22.53772 0 0 1 9.34028 1.82434z" />
            <path d="m36.889 19.94148h.11054a10.49572 10.49572 0 0 1 8.9538-4.47731c4.09 0 7.62731 1.65811 9.6719 5.4165a10.82974 10.82974 0 0 1 9.61787-5.4165c7.18432 0 9.6719 4.6984 9.6719 11.21947v18.07382h-10.05919v-13.54082c0-2.92974.11054-7.73785-4.14569-7.73785-4.64271 0-4.80811 4.36594-4.80811 7.73785v13.54082h-10.0592v-13.54082c0-3.15083 0-7.95893-4.47648-7.95893-4.47731 0-4.47731 4.80811-4.47731 7.95893v13.54082h-10.05922v-28.35328h10.05919z" />
            <path d="m110.2383 44.75746h-10.05838v-3.15h-.11137c-1.76865 2.81754-5.25027 4.08918-8.56649 4.08918-8.40109 0-14.31461-7.01975-14.31461-15.14325 0-8.12516 5.803-15.08922 14.20407-15.08922a11.5688 11.5688 0 0 1 8.7884 3.7027v-2.76269h10.05838zm-22.66-14.14921a6.46716 6.46716 0 0 0 12.93243 0 6.46645 6.46645 0 0 0 -12.93243 0z" />
            <path d="m123.583 21.04689h.11137c1.93488-3.48245 5.2511-5.03 9.174-5.03h1.93488v9.67271a8.13945 8.13945 0 0 0 -4.2554-.94c-5.58189 0-6.9649 3.7027-6.9649 8.56649v11.44137h-10.05835v-28.35328h10.0584z" />
            <path d="m150.31326 44.75746h-10.06v-20.06356h-3.2597v-8.28972h3.2597v-8.51247h10.06v8.51247h5.69326v8.28972h-5.69326z" />
            <path d="m158.33515 16.40886v28.34091h10.05972v-28.34091z" />
            <path d="m188.959 30.93987 6.74382-14.53569h11.27349l-14.81082 28.35328h-6.413l-14.86734-28.35328h11.27515z" />
            <path d="m236.54754 32.26636h-21.169c0 4.09 2.156 6.30082 6.3 6.30082a5.0283 5.0283 0 0 0 4.80894-2.59812h9.67273c-1.60242 6.6873-7.95893 9.72758-14.42515 9.72758-9.39679 0-16.41654-5.306-16.41654-15.08839 0-9.45165 6.46788-15.14408 15.69844-15.14408 9.83729 0 15.53056 6.07974 15.53056 15.75247zm-9.34028-5.8038a5.65886 5.65886 0 0 0 -5.69326-4.47648c-2.93058 0-5.36081 1.54757-5.9692 4.47648z" />
          </g>
          <path
            d="m162.28417 0a5.6271 5.6271 0 0 0 1.0893 11.14913 5.519 5.519 0 0 0 3.91495-1.6z"
            fill="#009fe3"
          />
        </Logo>
      )}

      {subheading && (
        <>
          <Heading>{heading}</Heading>
          <Subheading>{subheading}</Subheading>
        </>
      )}
    </Container>
    {navigation && (
      <Navigation>
        {navigation.map(({ label, link, active = false }) => (
          <NavigationLink active={active}>
            <Link to={link} active={active}>
              {label}
            </Link>
          </NavigationLink>
        ))}
      </Navigation>
    )}
  </>
)
