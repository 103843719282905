import React, { FC, ReactNode } from "react"
import styled from "styled-components"

type Props = {
  children: ReactNode
}

const Typo = styled.h1`
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.dark[100]};
  text-decoration: none;
  font-size: 2.074rem;
  line-height: 2.33325rem;

  margin: ${({ theme }) => `0 0 ${theme.spacing[8]}`};

  @media (min-width: 768px) {
    margin: ${({ theme }) => `0 0 ${theme.spacing[8]}`};
  }

  ::selection {
    background: ${({ theme }) => theme.colors.blue[5]};
  }
`

export const Display: FC<Props> = ({ children }) => <Typo>{children}</Typo>
