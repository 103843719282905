import React, { FC } from "react"
import styled from "styled-components"

import { Label } from "../../identity"

type Props = {
  items: string[]
}

const List = styled.ul`
  list-style: none;
  display: block;
  margin: ${({ theme }) => theme.spacing[12]} 0;
  padding: 0;
  position: relative;
  padding-left: 2.5rem;

  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
`
const Item = styled.li`
  margin: 0 0 ${({ theme }) => theme.spacing[6]};
  padding: 0;
  line-height: 1.5;
  padding-left: ${({ theme }) => theme.spacing[10]};
`

const Icon = styled.svg`
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  left: 0;
`

const Circle = styled.circle`
  fill: ${({
    theme: {
      colors: { blue },
    },
  }) => blue[75]};
`

const CheckMark: FC = () => (
  <Icon viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Circle cx="16" cy="16" r="16" />
    <path
      d="M10 16.5L14 20.5L22.5 12"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </Icon>
)

export const CheckList: FC<Props> = ({ items }) => (
  <List>
    {items.map(item => (
      <Item key={item}>
        <CheckMark />
        <Label>{item}</Label>
      </Item>
    ))}
  </List>
)
