import React, { FC, ReactNode } from "react"
import styled from "styled-components"

type Props = { children: ReactNode }

const Container = styled.div`
  width: 100%;
  display: block;
  position: relative;
  background: ${({ theme }) => theme.colors.blue[25]};
  margin: 0 0 ${({ theme }) => theme.spacing[24]};
`

const ImageWrapper = styled.div`
  padding: ${({ theme }) => `${theme.spacing[20]} 0`};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`

export const Stage: FC<Props> = ({ children }) => (
  <Container>
    <ImageWrapper>{children}</ImageWrapper>
  </Container>
)
