import React, { FC } from "react"
import styled from "styled-components"

const Container = styled.footer`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  background: ${({ theme }) => theme.colors.blue[5]};

  padding: ${({ theme }) => `${theme.spacing[10]} ${theme.spacing[8]} 0`};

  @media (min-width: 768px) {
    flex-direction: row;
    padding: ${({ theme }) => `${theme.spacing[16]} ${theme.spacing[8]}`};
  }
`

const Block = styled.div`
  display: block;
  margin: 0 0 ${({ theme }) => theme.spacing[10]};

  @media (min-width: 768px) {
    margin: 0 auto;
    width: 48rem;
  }
`

const Address = styled.address`
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: ${({ theme }) => theme.colors.dark[100]};
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 1rem 0 0;
`

const Link = styled.a`
  display: inline-block;
  margin: ${({ theme }) => `${theme.spacing[4]} 0`};
  color: ${({ theme }) => theme.colors.dark[100]};
  text-decoration: none;

  border-bottom: 2px solid #009fe3;
`

const Smartive: FC = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="64" height="64" fill="#002C46" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.8716 26.1413C34.5397 25.4414 32.6886 24.9674 31.2212 24.9674C30.2279 24.9674 28.5122 25.3963 28.5122 26.6153C28.5122 28.2633 31.2212 28.5116 32.3951 28.7374C36.4134 29.5501 39.4611 31.4464 39.4611 35.9388C39.4611 42.3275 33.6367 44.9914 27.993 44.9914C24.539 44.9914 21.1302 43.9981 18.1729 42.1921L21.3108 36.1646C23.0716 37.4062 26.0064 38.6252 28.1284 38.6252C29.212 38.6252 30.8374 38.106 30.8374 36.7741C30.8374 34.8778 28.1284 34.7875 25.442 34.0651C22.733 33.3653 20.0466 32.0333 20.0466 27.6764C20.0466 21.7166 25.442 19.0527 30.7471 19.0527C33.5013 19.0527 36.2554 19.4817 38.7612 20.6104L35.8716 26.1413Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.3306 19.0762C43.1182 19.5051 41.4251 21.4917 41.4251 23.8395C41.4251 26.5259 43.6149 28.7157 46.2787 28.7157C47.5881 28.7157 48.7845 28.1965 49.6424 27.3386L45.3306 19.0762Z"
      fill="#009FE3"
    />
  </svg>
)

const Mutabor: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44px"
    height="44px"
    viewBox="0 0 44 44"
    version="1.1"
  >
    <g
      id="Header,-Footer"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <path
        d="M21.9132943,2.42092749 C10.9264379,2.42092749 2.01983102,11.3275344 2.01983102,22.3143908 C2.01983102,33.3012471 10.9264379,42.207854 21.9132943,42.207854 C32.9001507,42.207854 41.8067575,33.3012471 41.8067575,22.3143908 C41.8067575,17.0383163 39.7108451,11.978336 35.9800971,8.24758798 C32.249349,4.51683992 27.1893688,2.42092749 21.9132943,2.42092749 L21.9132943,2.42092749 Z M21.9132943,43.800912 C13.2162853,43.800912 5.37588797,38.5612533 2.04891798,30.5257548 C-1.27805201,22.4902563 0.563784222,13.241826 6.7153815,7.09399573 C12.8669788,0.946165506 22.1165356,-0.890005187 30.1499947,2.44188638 C38.1834537,5.77377796 43.418306,13.6173834 43.4129824,22.3143908 C43.3984588,34.1801879 33.7790981,43.793658 21.9132943,43.800912 L21.9132943,43.800912 Z M30.3262149,30.7404771 L26.2579951,30.7404771 L26.2579951,20.6423392 L21.9132943,23.1964967 L17.542262,20.6423392 L17.542262,30.7404771 L13.5135394,30.7404771 L13.5135394,13.2958452 L21.9132943,18.3119997 L30.3262149,13.2958452 L30.3262149,30.7404771 Z"
        id="Shape-Copy-4"
        fill="#000"
        fill-rule="nonzero"
      />
    </g>
  </svg>
)

export const Footer: FC<{
  withMutaborAsPartner?: boolean
}> = ({ withMutaborAsPartner }) => (
  <Container>
    {withMutaborAsPartner && (
      <Block>
        <Mutabor />
        <Address>
          MUTABOR Technologies GmbH
          <br />
          Königstrasse 28
          <br />
          D-22767 Hamburg
          <br />
          <Link href="https://www.mutabor.de">mutabor.de</Link>
        </Address>
      </Block>
    )}
    <Block>
      <Smartive />
      <Address>
        smartive AG
        <br />
        Pfingstweidstrasse 60
        <br />
        CH-8005 Zürich
        <br />
        <Link href="https://smartive.ch">smartive.ch</Link>
      </Address>
    </Block>
  </Container>
)
