import React, { FC, ReactNode } from "react"
import styled from "styled-components"

type Props = {
  children: ReactNode
}

const Typo = styled.p`
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.dark[100]};
  text-decoration: none;
  font-size: 1.1rem;
  margin: 0 0 1.25rem;
  line-height: 1.7rem;
  letter-spacing: 0.125px;

  ::selection {
    background: ${({ theme }) => theme.colors.blue[5]};
  }
`

export const BodyText: FC<Props> = ({ children, ...props }) => (
  <Typo {...props}>{children}</Typo>
)
