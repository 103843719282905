import React, { FC, ReactNode } from "react"
import styled from "styled-components"

type Props = {
  children: ReactNode
  number?: string
}

type TypoProps = {
  number?: string
}

const Typo = styled.h2<TypoProps>`
  margin-block-start: 0;
  margin-block-end: 0;

  hyphens: manual;
  font-weight: 400;
  position: relative;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.dark[100]};
  text-decoration: none;
  font-size: 1.728rem;
  line-height: 2.592rem;
  margin: 0 0 ${({ theme }) => theme.spacing[6]};

  &:before {
    content: ${({ number }) => `'${number}'`};
    color: ${({ theme }) => theme.colors.blue[100]};
    margin-right: 1rem;

    @media (min-width: 768px) {
      position: absolute;
      left: -3rem;
      margin-right: 0;
    }
  }

  ::selection {
    background: ${({ theme }) => theme.colors.blue[5]};
  }
`

export const Title: FC<Props> = ({ children, number }) => (
  <Typo number={number ? `${number}.` : ""}>{children}</Typo>
)
